body {
    font-family: $font-primary;
    font-size: 18px; 
    font-weight: 400;
    color: $text-color-defaut;
    background-color: white;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4) !important;
}
aside {
    /* width */
    ::-webkit-scrollbar {
      width: 6px !important;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1) !important;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2) !important;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4) !important;
    }
}

/* HEADER */
header#main-header {
    @media(min-width: 1200px) {
        position: sticky;
        top: 0;
        padding: 0 50px 0 40px;
    }
    a {
        height: 54px;
        width: 164px;
        margin-left: auto;
        //margin-right: auto;
        line-height: 49px!important;  
        padding: 0;
        font-family: $font-primary!important;
        font-size: 14px!important;
        &.disabled {
            opacity: 1!important;
        }
        @media(min-width: 375px) {
            width: 184px;
        }
        
        @media(min-width: 768px) {
            font-size: 16px!important;
            width: 236px;
            height: 54px;
        }
        @media(min-width: 1200px) {
            font-size: 26px;
            margin-right: 30px;
        }
    }
    
    &.in-selection {
        background: inherit;
        background-color: #003253;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px 0;
        
        @media(min-width: 1200px) {
            //background-image: url(../img/logo-landing2.png);
            background-position: 20px 20px;
            background-repeat: no-repeat;
            position: sticky;
            top: 0;
        }
        h1 {
            display: none!important;
        }
        
        .lots-count {
            color: #fff;
            display: block!important;
            font-size: 16px;
            line-height: 1 !important;
            margin-bottom: 10px;
            @media(min-width: 768px) {
                margin-left: auto;
            }
            @media(min-width: 1200px) {
                font-size: inherit;
                position: absolute;
                right: 350px;
                margin-bottom: 0;
            }
        }
        
        a {
            width: 236px;
            margin-right: auto;
            
            @media(min-width: 1200px) {
                margin-right: 70px;
            }
        }
    }
    
    @media(min-width: 1200px) {
        height: 95px;
    }
        
    img {
        margin-right: 90px;
        height: 30px;
        width: auto;
        
         @media(min-width: 1200px) {
            width: 94px;
            height: 51px;
        }
    }
    
    h1 {
        font-weight: 600;
        line-height: 1!important;
        font-size: 22px;
        margin-bottom: 0;
        
        @media(min-width: 768px) {
            font-size: 20px;
            line-height: 24px!important;
            padding-left: 134px;
        }
        @media(min-width: 1200px) {
            padding-left: 180px;
            font-size: 26px;
        }
    }
    
}

/* BANNER*/
#banner {
    max-width: 1440px;
    .banner-container {
        position: relative;
        height: 300px;
        
        @media(min-width: 768px) {
            height: 400px;
        }
        @media(min-width: 1200px) {
            height: 636px;
        }
        
        .banner-overview {
            position: absolute;
            bottom: 0;
            width: 100%;
            
            @media(min-width: 768px) {
                width: 410px;
                top: calc(50% - 189px / 2);
                left: calc(50% - 410px / 2);
                
            }
            
            @media(min-width: 1200px) {
                width: 556px;
                top: calc(50% - 148px / 2);
                left: calc(50% - 556px / 2);
                
            }
            .overview-status {
                width: 216px;
                //font-family: $font-primary;
                font-size: 16px;
                line-height: 28px;
                font-weight: bold;
                transform: translateY(21px);
                padding-top: 3px;
                
                @media(min-width: 768px) {
                    width: 299px;
                }
            }
            .overview-program-name {
                font-weight: 500;
                background-color: rgba(0, 0, 0, 0.8);
                padding: 34px 0 13px 0 ;
                font-size: 20px;
                line-height: 26px;
                
                :last-child {
                    font-weight: 900;
                }
                
                @media(min-width: 768px) {
                    font-size: 24px;
                    line-height: 32px;
                }
                @media(min-width: 1200px) {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
}

/* TITLES */
.booking-title {
    margin-bottom: 0;
    text-transform: inherit;
    font-size: 20px;
    line-height: 28px;
    padding: 0 27px;
    @media(min-width: 768px) {
        font-size: 32px;
        line-height: 42px;
        padding: 0 20px;
    }
    @media(min-width: 1200px) {
        font-size: 22px;
        line-height: 28px;
        font-size: 32px;
        line-height: 42px;
    }
       
    &:after {
        content: ' ';
        width: 70px;
        height: 5px;
        display: block;
        margin: 12px auto 0 auto;
        background-color: $color-primary;
    }
}

/* MAIN CONTENT*/

#main {
    max-width: 1168px;
    
    .banner-sub {
        background-color: #fff;
        border-radius: 0 0 50px 50px;
        margin: 0 10px 30px 10px;
        height: 130px;
        font-size: 18px;
        line-height: 24px;
        padding: 15px 10px 0 10px;
        
        @media(min-width: 375px) {
            padding-left: 27px;
            padding-right: 27px;
        }
        @media(min-width: 768px) {
            margin: 0 20px 40px 20px;
            padding-top: 23px;
            font-size: 24px;
            line-height: 34px;
        }
        @media(min-width: 1200px) {
            //border-radius: 0 0 50px;
            margin: 0 0 42px 0;
            font-size: 32px;
            line-height: 42px;
        }
    }
    
    #steps {
        margin-bottom: 30px;
        
        @media(min-width: 768px) {
            margin-bottom: 66px;
        }
        @media(min-width: 1200px) {
            margin-bottom: 86px;
        }
        
        .steps-container {
            margin-top: 30px;
            padding: 0 10px;
            
            @media(min-width: 768px) {
                padding: 0 20px;
            }
            @media(min-width: 1200px) {
                padding: 0 ;
            }
            .step {
                width: 100%;
                background-color: #fff;
                padding: 20px 10px 30px 10px;
                
                &:first-child {
                    margin-bottom: 30px;
                }
                @media(min-width: 768px) {
                    height: 490px;
                    padding: 20px 30px;
                }
                @media(min-width: 1200px) {
                    padding: 20px 35px 20px 35px;
                    height: 547px;
                    width: 570px;
                }
                
                .step-header {
                    height: 104px;
                    margin-bottom: 30px;
                    
                    @media(min-width: 768px) {
                        margin-bottom: 15px;
                    }
                    @media(min-width: 1200px) {
                        height: 140px;
                        margin-bottom: 0;
                    }
                    
                    .step-number {
                        font-size: 120px;
                        
                        &#step11 {
                            margin-right: 48px;
                             
                             img {
                                height: 98px;
                                width: 40px;
                                 
                             }
                        }
                        &#step22 {
                             margin-right: 30px;
                             
                             img {
                                height: 98px;
                                width: 70px;
                                 
                             }
                        }
                        img {
                            max-width: inherit;
                        }
                        @media(min-width: 1200px) {
                            font-size: 140px;
                        }
                    }
                    .step-intro {
                        font-size: 17px;
                        line-height: 26px;
                        
                        @media(min-width: 768px) {
                            font-size: 19px;
                            line-height: 26px;
                            margin-top: 15px;
                        }
                        @media(min-width: 1200px) {
                            margin-top: 20px;
                        }
                    }
                    
                }
                .step-body {
                    font-size: 17px;
                    line-height: 25px;
                    
                    @media(min-width: 768px) {
                        font-size: 19px;
                        padding: 0 24px;
                    }
                }
                .step-footer {
                    button {
                        width: 248px;
                        
                    }
                    
                    &.bg-grey {
                        background-color: #f1f1f1;
                        padding: 15px;
                        height: auto;
                        border-radius: 27px;
                        
                        /*
                        @media(min-width: 768px) {
                            margin-top: 30px;
                            padding: 20px 20px 0 20px;
                            
                            
                            width: 389px;
                        }
                        */
                        @media(min-width: 1200px) {
                            height: auto;
                        }
                        h6 {
                            text-align: center;
                            position: relative;
                            z-index: 1;
                            font-size: 15px;
                            line-height: 23px;
                            
                            @media(min-width: 375px) {
                                font-size: 17px;
                                line-height: 25px;
                            }
                            @media(min-width: 768px) {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
            @media(min-width: 1200px) {
                
            }
        }
        
    }
    .stock {
        margin-top: 30px;
        background-color: #fff;
    }
    
    #maquette {
        padding-top: 45px;
        margin-bottom: 20px;
        
        iframe {
            height: 226px;
            width: 100%;
            
            @media(min-width: 768px) {
                height: 431px;
            }
            @media(min-width: 1200px) {
                height: 693px;
                
            }
        }
    }
    
}

footer#landing-footer {
    
    background-color: #f9f9f9;
    
    padding: 45px 0 0 0;
    
    @media(min-width: 768px) {
        padding: 50px 0 0 0;
    }
    @media(min-width: 1200px) {
        margin-top: 70px;
    }
    h2 {
        @media(min-width: 768px) {
            padding: 0;
        }
    }
    #benefits {
        
        @media(min-width: 1200px) {
            margin-top: 70px;
            padding: 0 105px 30px 135px;
        }
        
        
        ul {
            margin-top: 30px;
            padding: 0 20px 0 20px;
            position: relative;
            list-style: none!important;
            
            @media(min-width: 768px) {
                padding: 0 30px 30px 80px;
            }
            @media(min-width: 1200px) {
                margin-top: 0;
                max-width: 590px;
                padding: 0 30px 30px 60px;
                
                &:after {
                    content: ' ';
                    display: block;
                    width: 100%;
                    max-width: 480px;
                    height: 1px;
                    background-color: #979797;
                    position: absolute;
                    bottom: 20px;
                    left: 54px;
                    
                }
            }
            li {
                font-size: 17px;
                line-height: 25px;
                margin-bottom: 20px;
                position: relative;
                padding-left: 30px;
                
                &:before {
                    content: '';
                    display: inline-block;
                    height: 19px;
                    width: 19px;
                    left: 0px;
                    top: 2px;
                    position: absolute;
                    background : url(/img/puce-emerige.svg);
                }
                @media(min-width: 768px) {
                    font-size: 19px;
                    padding-left: 45px;
                }
                
            }
        }
        .benefits-banner {
            
            height: 180px;
            width: 100%;
            margin-bottom: 40px;
            
            @media(min-width: 768px) {
                height: 369px;
                margin-bottom: 60px;
            }
            @media(min-width: 1200px) {
                width: 600px;
                height: 369px;
                //margin-right: 91px;
            }
        }
    }
    .sub-footer {
        background-color: #fff;
        
        padding: 10px 27px 33px 27px;
        font-size: 14px;
        line-height: 42px;
        
        @media(min-width: 768px) {
            padding-top: 44px;
        }
        @media(min-width: 1200px) {
            padding-left: 161px;
        }
        
        .footer-ml {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 25px;
        }
        
        .links {
            
            a:hover {
                color: #000;
            }
            span { 
                margin: 0 5px;    
            }
        }
        img {
            margin-top: 20px;
            
            @media(min-width: 768px) {
                margin-top: 30px;
                
            }
            @media(min-width: 1200px) {
                margin-right: 213px!important;
            }
        }
    }
    
}

/* ANIMATIONS */
.table-lots-details {
    
    tr {
        
        th {
            background-color: #f5f5f5;
        }
        
        td {
            
            background-color: #fff!important;
        }
    }
    
    .btn-cta {
        width: 120px;
        border-radius: 0;
        padding: 10px 15px;
        border: solid 1px #003253;
        font-size: 14px;
        text-transform: unset;
        color: #003253;
        margin: 0 5px;

        &.selected {
            background-color: #003253;
            color: #fff;
        }
        
        &:disabled {
            border: solid 1px #efefef;
            background-color: #efefef;
            color: #fff;
            opacity: 1;
            cursor: not-allowed;
        }
    }
    
    .btn-link {
        border: 0;
        border-radius: 0;
        width: 120px;
        font-size: 14px;
        text-transform: unset;
        color: $color-primary;
        margin: 0 5px;
        padding: 0;
        cursor: default;
        font-weight: normal;
        
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

#stocks {
    
    .container {
        padding: 0;
    }
    
    .inner {
        padding: 0;
        
        @media(min-width: 992px) {
            
            //padding: 0 20px;
        }
    }
    
    .right20 {
        
        @media(max-width: 992px) {
            
            float: right;
            line-height: 40px;
            font-size: 20px;
        }
    }

    .table-type-1 {
        margin-bottom: 0;
        padding-bottom: 0;
        
        table .trigger {
            border-color: #f1f1f1;
        }
        .table-lots-details {
            th {
                font-weight: 400;
            }
        }
        td.ext {
            width: 173px;
        }
    }
    
    footer {
        padding-top: 15px;
        background: #f1f1f1;
    }
}

#contact-form {

    .form-control:not(select):not(.prefix_tel) {
        border: solid 1px transparent!important;
        
        &.has-error {
            border-color: #dc3545!important;
        }
    }
    
    .tel-group.has-error {
        border: solid 1px #dc3545;
    }
    
    .btn--pink {
        //font-family: Barlow;
        font-size: 16px;
        font-weight: 800;
        background-color: $color-primary!important;
        
        &:hover {
            background-color: $color-primary!important;
        }
        
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    
    .optin-checkbox {
        
        a {
            text-decoration: underline;
        }
    }
    
    #errors {
        
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            
            li {
                font-size: 14px;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.tabs--mobile {
    
    .section {
        margin: 0!important;
    }
    
    .table-type-1-mobile {

        .trigger {
            
            h4 {
                max-width: 35%!important;
                width: 55%!important;
            }
            &.active {
                
                background-color: #f5f5f5;
                border-bottom: solid 1px #b4b4b4;
            }
        }
        
        .name {
            
            width: 100%!important;
            
            div {
                font-size: 16px;
                
                button {
                    padding: 0;
                    border: 0;
                    text-transform: unset;
                    color: inherit;
                    text-decoration: underline;
                    font-weight: 600;
                }
            }
        }
        
        .price {
            
            .price--left {
                padding: 0!important;
                text-align: left!important;
            }
            
            .price--right {
                padding: 0 20px 0 0!important;
                
            }
        }
        
        .count-mobile-rdv {
            font-size: 12px!important;
            color: $color-primary;
        }
        
        .actions {
            padding: 10px;
            
            .btn-cta {
                width: 130px;
                border-radius: 0;
                border: solid 1px #003253;
                text-transform: unset;
                color: #003253;
                margin: 0 auto;
                display: block;
                padding: 6px 10px;
                line-height: 16px;
                font-weight: 500;
                font-size: 12px;
                
                strong {
                    display: block;
                    font-weight: 800;
                    font-size: 14px;
                }
                    
                &.cta-decouverte {
                    font-family: $font-primary!important;
                    //font-weight: 800;
                    margin-bottom: 10px;
                }
                
                &.selected {
                    background-color: #003253;
                    color: #fff;
                }
                
                &:disabled {
                    border: solid 1px #efefef;
                    background-color: #efefef;
                    color: #fff;
                    opacity: 1;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.tabs footer p {
    margin: 0!important;
}

#modal-lot_details {
    
    .modal-body {
        
        padding: 25px 0 0 0;
        
        #lot_id {
            font-size: 19px;
            color: #003253;
            font-weight: 600;
        }
        
        #lot_exterieur {
            font-size: 16px;
        }
        
        #lot_prix {
            font-size: 22px;
            font-weight: 800;
            background-color: #f5f5f5;
            color: #003253;
            padding: 15px 0;
        }
        
        #lot_plans {
            
            p {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
    
    .modal-footer {
        
        padding: 0;
        border-top: 0;
        
        .btn {
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: #003253;
        }
    }
}


// MODAL 
    .modal {
        padding-right: 0 !important;
        .modal-content {
            background-color: white;
            z-index: 0;
            border: none;
            border-radius: 0;
            background-clip: border-box;
            text-align: justify;
            .modal-header {
                padding: 20px 20px;
                @media (min-width: 768px) {
                    padding: 20px 40px;
                }
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                .close {
                    opacity: 1;
                    height: 20px;
                    width: 20px; 
                    padding: 0;
                    margin: auto 0;
                    background-image: url(../img/close.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 20px;
                }
                .modal-title {
                    font-family: $font-primary;
                }
            }
            .modal-body {
                padding: 20px;
                @media (min-width: 768px) {
                    padding: 40px;
                }
                
                h5, p {
                    margin-bottom: 1em;
                }
                h4 {
                    margin-bottom: 1.2em;
                    margin-top: 1em;
                    text-align: center;
                }
                a {
                    text-decoration: underline;
                    font-style: italic;
                }
            }
        }
        
        &.transparent {
            background-color: rgba(0,0,0, 0.5);
            .modal-xl {
                margin: 0 auto;
                max-width: 90% !important;
                @media(min-width: 768px) {
                    max-width: 70% !important;
                }

                .modal-content {
                    background-color: transparent;
                    .modal-header {
                        padding: 20px 0;
                        border-bottom: 0 solid $light-grey;
                        .modal-title {
                            color: white !important;
                        }
                        .close {
                            background-image: url(../img/close-white.svg);
                        } 
                    }
                    .modal-body {
                        padding: 0;
                    }
                }
            }
        }
    }

.upper {
    vertical-align: super!important;
}

// RESET bootstrap and webkit-appearance
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
        color: $text-color-defaut;
    }
    
    a {
        color: $text-color-defaut;
        &:hover {
            text-decoration: none;   
        }
    }
    
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    
    :-webkit-direct-focus {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 0 !important;
    }
    
    [role="bouton"],
    [type="bouton"],
    button, 
    .btn, 
    .dropdown, 
    .dropdown:focus, 
    a, 
    a:focus,
    .dropdown-toggle, 
    .dropdown-toggle:focus,
    .dropdown-menu, 
    .dropdown-menu:focus, {
        outline-style: none;
    }
    
    .btn, 
    .btn:focus, 
    .btn-primary:focus {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
        outline-style: none;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus
    input:-webkit-autofill, 
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    
// --END RESET 