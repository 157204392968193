#banner-top {
    @media (min-width: 1200px) {
        height: 100vh;
    }

    nav {
        height: 50px;
        @media (min-width: 768px) {
            height: 80px;
        }

        .logo img {
            width: auto;
            height: 40px;
            margin-left: 10px;
            @media (min-width: 768px) {
                height: 60px;
                margin-left: 40px;
            }
        }

        ul {
            position: relative;
            z-index: 3;
            width: calc(100% - 27%);
            list-style: none;
            padding: 0;
            margin: 0 40px 0 0;
            text-align: right;
            @media (min-width: 1200px) {
                margin: 0;
            }
            li {
                display: inline;
                margin: 0 5px;
                font-size: 16px;
                @media (min-width: 1366px) {
                    margin: 0 10px;
                    font-size: 18px;
                }
                @media (min-width: 1500px) {
                    margin: 0 20px;
                }
                a {
                    &:hover {
                        color: $green;
                    }
                }
            }
        }

        .next-program {
            background-color: $blue;
            background-image: url("/img/distinction/triangle-header.svg");

            background-repeat: no-repeat;
            background-position: -35px center;
            background-size: 67px;
            margin-left: auto;
            height: 100%;
            width: 50%;
            @media (min-width: 768px) {
                width: 40.9%;
                background-position: -51px center;
                background-size: 110px;
            }
            @media (min-width: 992px) {
                width: 30.7%;
            }
            @media (min-width: 1200px) {
                width: 30.4%;
            }
            @media (min-width: 1366px) {
                width: 26.4%;
            }

            .infos {
                color: white;
                text-transform: uppercase;
                font-size: 7px;
                line-height: 8px;
                width: 48px;
                @media (min-width: 768px) {
                    width: 95px;
                    font-size: 14px;
                    line-height: 16px;
                }
                @media (min-width: 1200px) {
                    width: 130px;
                }
            }

            .rooftop img {
                width: auto;
                height: 25px;
                margin-left: 7px;
                margin-right: 10px;
                @media (min-width: 768px) {
                    height: 49px;
                    margin-left: 14px;
                    margin-right: 23px;
                }
                @media (min-width: 992px) {
                    margin-right: 20px;
                }
                @media (min-width: 1366px) {
                    margin-right: 20px;
                }
            }
        }
    }

    header {
        .content-text,
        .banner {
            //position: relative;
            @media (min-width: 1200px) {
                height: calc(100vh - 80px);
            }
        }

        .content-text {
            @media (min-width: 1200px) {
                width: 50%;
            }
            @media (min-width: 1200px) {
                padding-left: 25px;
                padding-right: 25px;
            }
            @media (min-width: 1366px) {
                padding-left: 50px;
                padding-right: 50px;
            }

            .logo {
                position: relative;
                @media (min-width: 992px) {
                    top: -70px;
                    left: 40px;
                }
                @media (min-width: 1200px) {
                    top: -60px;
                    left: 0;
                }
                @media (min-width: 1366px) {
                    left: -10px;
                }

                img {
                    width: auto;
                    height: 95px;
                }
            }

            .banner-pers {
                position: relative;
                width: 100%;
                height: 168px;
                @media (min-width: 768px) {
                    height: 400px;
                }

                .logos-banner {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    img {
                        height: auto;
                        width: 56px;
                        @media (min-width: 768px) {
                            width: 95px;
                        }
                    }
                    @media (min-width: 768px) {
                        bottom: 10px;
                        right: 10px;
                    }
                }
            }

            h1 {
                //color: $gold;
                font-size: 24px;
                line-height: 29px;
                margin-top: 30px;
                letter-spacing: -0.93px;
                @media (min-width: 768px) {
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -1.47px;
                    margin-top: 54px;
                }
                @media (min-width: 992px) {
                    margin-top: 0;
                }
                @media (min-width: 1200px) {
                    font-size: 46px;
                    line-height: 46px;
                    letter-spacing: -1.67px;
                }

                .l0 {
                    font-size: 22px;
                    line-height: 1.27;
                    letter-spacing: -0.73px;
                    color: #2a2a2a;
                    margin-bottom: 15px;
                }
                .l1,
                .l2,
                .l3 {
                    color: #d6267e;
                    font-size: 40px;
                    line-height: 1.05;
                    letter-spacing: -1.33px;
                }
                .l4 {
                    color: $gold;
                    font-size: 22px;
                    line-height: 1.27;
                    letter-spacing: -0.73px;
                    margin-top: 5px;

                    sup {
                        font-size: 10px;
                        line-height: 2.8;
                        letter-spacing: -0.33px;
                    }
                }
            }

            .pictos-program {
                width: calc(100% - 80px);
                margin-top: 24px;
                margin-bottom: 44px;
                margin-left: auto;
                margin-right: auto;
                @media (min-width: 768px) {
                    width: calc(100% - 280px);
                    margin-top: 32px;
                    margin-bottom: 32px;
                }
                @media (min-width: 992px) {
                    width: calc(100% - 80px);
                    margin-top: 28px;
                    margin-bottom: 30px;
                }
                @media (min-width: 1200px) {
                    width: calc(100%);
                    max-width: 600px;
                }

                .picto-program-group {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 17px;
                    @media (min-width: 768px) {
                        width: 134px;
                    }
                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                    @media (min-width: 1200px) {
                        width: 180px;
                    }
                    .block-picto {
                        width: 40px;
                        margin-right: 17px;
                        @media (min-width: 768px) {
                            width: auto;
                            margin: 0 auto 17px auto;
                            height: 60px;
                        }
                        img {
                            display: block;
                            max-width: 40px;
                            @media (min-width: 768px) {
                                max-width: inherit;
                                margin: 0 auto;
                            }
                            @media (min-width: 992px) {
                            }
                            @media (min-width: 1200px) {
                            }
                        }
                    }
                    p {
                        margin: 0;
                    }
                }
            }

            .call-to-action {
                @media (min-width: 768px) {
                    max-width: 211px;
                    margin: 0 5px 66px 5px !important;
                }
                @media (min-width: 992px) {
                    margin: 0 5px 90px 5px !important;
                }
                @media (min-width: 1200px) {
                    width: 48%;
                    max-width: 215px;
                    margin: 0 5px;
                }
            }

            .picto-bottom {
                margin-bottom: 24px;

                .logo-copro {
                    width: auto;
                    height: 51px;
                    margin-left: 20px;
                    @media (min-width: 992px) {
                        margin-left: 0;
                        margin-right: 0;
                        height: 77px;
                    }
                    @media (min-width: 1200px) {
                        margin-right: 0;
                        height: 75px;
                    }
                }

                .logo-reseau {
                    width: auto;
                    height: 44px;
                    margin-right: 20px;
                    @media (min-width: 768px) {
                        margin-right: 0px;
                        margin-left: 32px;
                        height: 75px;
                    }
                    @media (min-width: 1200px) {
                        margin-left: 0;
                        height: 63px;
                    }
                    @media (min-width: 1440px) {
                        height: 75px;
                    }
                }
            }
        }

        .banner {
            position: relative;

            @media (min-width: 1200px) {
                width: 50%;
            }

            .banner-pict {
                //position: relative;

                .banner-pers {
                    position: relative;
                    @media (min-width: 992px) {
                        margin-left: auto;
                        width: calc(100% - 40px);
                        height: 568px;
                    }
                    @media (min-width: 1200px) {
                        width: calc(100% - 40px);
                        height: calc(100vh - 200px);
                    }

                    #patch-offre {
                        top: 17px;
                        left: -40px;
                    }

                    .logos-banner {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        @media (min-width: 1200px) {
                            bottom: 20px;
                            right: 20px;
                        }
                    }
                }

                .banner-text {
                    color: white;
                    background-color: $green;
                    padding: 25px 20px;
                    @media (min-width: 768px) {
                        padding: 25px 30px;
                    }
                    @media (min-width: 992px) {
                        background-color: transparent;
                        margin-left: auto;
                        width: calc(100% - 40px);
                        padding-top: 24px;
                        padding-bottom: 24px;
                        padding-right: 30px;
                    }
                    @media (min-width: 1200px) {
                        width: calc(100% - 80px);
                        padding: 0;
                        position: absolute;
                        bottom: 20px;
                        right: 40px;
                    }

                    .picto-cta {
                        @media (min-width: 768px) {
                        }
                        @media (min-width: 992px) {
                        }
                        @media (min-width: 1200px) {
                            margin-right: 20px;
                        }
                        img,
                        svg {
                            width: 44px;
                            height: 44px;
                            margin: 0 4px;
                            @media (min-width: 992px) {
                                width: 55px;
                                height: 55px;
                            }
                        }
                    }

                    .text {
                        margin-top: 10px;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 300;
                        @media (min-width: 768px) {
                            width: auto;
                            margin-top: 0;
                            margin-left: 20px;
                        }
                        @media (min-width: 992px) {
                            margin-top: 0;
                            margin-left: 10px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 16px;
                            line-height: 20px;
                            margin-left: 0;
                        }
                    }
                }
            }

            @media (min-width: 992px) {
                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 384px;
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                    background-color: $green;
                }
            }
        }

        .offre-commercial {
            img {
                height: auto;
                &.dates {
                    width: 60%;
                    margin: 30px auto 15px auto;
                    display: block;
                    max-width: 321.3px;
                    @media (min-width: 1200px) {
                        margin: 0 auto 15px auto;
                    }
                }
            }
            .flex {
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (min-width: 1200px) {
                    flex-direction: row;
                    justify-content: center;
                }
                img {
                    height: auto;
                    width: 60%;
                    &.remise,
                    &.frais {
                        @media (min-width: 500px) {
                            max-width: 321.3px;
                        }
                        @media (min-width: 1200px) {
                            max-width: 266px;
                        }
                    }
                    &.separator {
                        width: 100%;
                        max-width: 30px;
                        margin: 15px 0;
                        @media (min-width: 1200px) {
                            margin: 0 15px;
                        }
                    }
                }
            }
        }
        .patch-banner {
            position: absolute;
            top: 20px;
            left: 40px;
            @media (min-width: 1200px) {
                left: -40px;
            }
            img {
                height: auto;
                width: 196px;
                @media (min-width: 1200px) {
                    width: 316px;
                }
            }
        }
    }

    &.elegance {
        nav {
            ul {
                li {
                    a {
                        &:hover {
                            color: $gold;
                        }
                    }
                }
            }
            .next-program {
                background-color: $dark-gold;
                background-image: url("/img/elegance/triangle-header.svg");

                .infos {
                    color: white;
                    text-transform: uppercase;
                    font-size: 7px;
                    line-height: 8px;
                    width: 48px;
                    @media (min-width: 768px) {
                        width: 95px;
                        font-size: 14px;
                        line-height: 16px;
                    }
                    @media (min-width: 1200px) {
                        width: 130px;
                    }
                }
            }
        }
        header {
            .banner {
                .banner-pict {
                    .banner-text {
                        background-color: $gold;
                    }
                }

                @media (min-width: 992px) {
                    &:after {
                        background-color: $gold;
                    }
                }
            }
        }
    }
}
