/* COUNTDOWN */
#countdown-container {
    /* ENSEMBLE DES 3 CARRES */
    .countdown-numbers {
        //padding: 0 20px;
        margin-top: 17px;
        margin-left: auto;
        margin-right: auto;
        max-width: 252px;
        @media (min-width: 768px) {
            max-width: 420px;
        }

        width: 100%;
        /* CARRE + SOUS TITRE */
        .number {
            > div {
                /* CARRE */
                &:first-child {
                    width: 54px;
                    height: 54px;
                    background-color: $color-secondary;
                    border-radius: 12px;
                    color: #fff;
                    font-size: 28.8px;
                    font-weight: 800;
                    line-height: 42px;
                    padding-top: 7.5px;
                    @media (min-width: 768px) {
                        padding-top: 30px;
                        font-size: 48px;
                        width: 90px;
                        height: 90px;
                    }
                    @media (min-width: 1200px) {
                        //line-height: 37px;
                    }
                }

                /* SOUS TITRE */
                &:last-child {
                    font-size: 9.6px;
                    font-weight: 800;
                    line-height: 42px;
                    color: $color-secondary;
                    @media (min-width: 768px) {
                        font-size: 16px;
                        line-height: 70px;
                    }
                }
            }

            @media (min-width: 1200px) {
                // width: 115px;
                // height: 78px;
            }

            /* CODE POUR LE DERNIER CARRE */
            &:last-child {
                div {
                    &:first-child {
                        background-color: rgba(168, 190, 198, 0.5);
                        color: $color-primary;
                    }
                }
            }
        }
    }
}

/* MODAL */
#modal-register {
    .modal-dialog {
        max-width: 900px;
    }

    .modal-content {
        border-radius: 0;
        border: 0;
    }

    .modal-header {
        border: 0;

        .step-back {
            font-size: 16px;
            color: black;
            border: 0;
            border-radius: 0;
            padding: 0;
            text-transform: unset;
            font-weight: 400;
            padding-left: 52px;
            line-height: 37px;
            background-image: url(../img/oval-arrow.svg);
            background-repeat: no-repeat;
            background-size: 37px;
        }
    }

    .modal-footer {
        background-color: $light-grey;
        padding: 30px;
        border: 0;
        text-align: center;

        p {
            font-size: 18px;
            display: block;
            width: 100%;
            line-height: 2.21;
            margin: 0 0 15px 0;
            font-weight: 500;
        }
    }
    .btn--pink {
        border-radius: 32px;
        background: $color-primary;
        font-family: $font-primary;
        font-weight: 700;
        width: 300px;
        margin: auto;
        color: #fff;
        &:hover,
        &:focus {
            background: $color-secondary;
        }
    }

    .viewport {
        overflow: hidden;
    }

    .slots-container {
        margin: 35px auto;
        display: block;

        @media (min-width: 1200px) {
            max-width: 750px;
        }
        .btn-link {
            border: 0;
            color: black;
            text-transform: unset;
            font-size: 14px;
            text-decoration: none;
            border-radius: 0;
            margin-top: 40px;
        }
        #selected-lots {
            height: 110px;
            padding-top: 13px;
            line-height: 26px;

            @media (min-width: 768px) {
                height: auto;
            }

            @media (min-width: 1200px) {
                line-height: 48px;
                padding-top: 0;
            }
            .selected-text {
                font-weight: 900;
            }
        }
        .card {
            border: 0;
            line-height: 48px;
            width: 100%;
            display: inline-block;
            padding: 0;
            background: $color-secondary;
            border-radius: 0;
            text-align: center;
            font-weight: 400;
            font-size: 22px;
            span {
                color: white;
                &#selected-text {
                    font-weight: 900;
                }
            }
        }

        h2 {
            font-size: 32px;
            text-transform: unset;
            text-align: center;
            font-weight: 700;
            margin: 25px 0;
            .color-pink {
                color: $gold;
            }
        }

        .toggle-slots {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .grid-container-steps {
        grid-template-columns: 100% 100% 100% 100%;
        display: grid;
        position: relative;
        animation-duration: 1s;

        .grid-item-3 {
            padding: 70px 15px 0 15px;
            height: 800px;
            position: relative;

            @media (min-width: 1200px) {
                padding: 0 30px 0 30px;
            }
        }
        .grid-item-4 {
            padding: 70px 15px 0 15px;
            height: 420px;
            position: relative;

            @media (min-width: 1200px) {
                padding: 0 30px 0 30px;
            }
        }

        &[data-step="1"] {
            height: 616px;

            #select-rdv-type {
                margin-top: 45px;
                margin-bottom: 45px;
                line-height: 20px;
            }
            .call-to-action {
                height: 82px;
                //background: red;
                margin-bottom: 30px;
            }
            .btn-slot {
                display: none;
            }
        }
        &[data-step="2"] {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            max-height: 1040px;

            @media (min-width: 1200px) {
                //max-height: 770px;
                //overflow-y: auto;
            }
            .btn-slot {
                display: block;
            }
        }

        &[data-step="3"] {
            -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
        }

        &[data-step="4"] {
            -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
            max-height: 420px;
        }

        h2 {
            font-size: 32px;
            text-transform: unset;
            text-align: center;
            font-weight: 700;
            margin: 25px 0;
        }

        p:not(.appointment-ml) {
            font-size: 19px;
            line-height: 25px;
            margin-bottom: 25px;
        }

        .close {
            position: absolute;
            top: 5px;
            right: 10px;
            font-size: 50px;
            font-weight: 100;

            @media (min-width: 1200px) {
                top: 45px;
                right: 40px;
            }
        }

        .appointment-ml {
            font-size: 14px;
            color: $grey;
        }

        .form-container {
            max-width: 750px;
            margin: auto;
            display: block;

            h2 {
                font-size: 32px;
                text-transform: unset;
                text-align: center;
                font-weight: 700;
                margin: 25px 0;
            }

            h4 {
                text-align: center;
                font-size: 19px;
                font-weight: 400;
            }

            p.mentions-legales {
                font-size: 12px;
                color: $grey;
            }
        }
    }

    .btn-slot {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 50px;
        height: calc(100% + 20px);
        border: 0;
        border-radius: 0;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 15px;

        &.prev {
            left: -50px;
            background-image: url("../img/pict-arrow-left-slider-white.svg");
        }

        &.next {
            right: -50px;
            background-image: url("../img/pict-arrow-right-slider.svg");
        }
    }

    .grid-container-slots {
        display: grid;
        position: relative;
        animation-duration: 1s;
        grid-column-gap: 10px;
        grid-row-gap: 20px;

        padding: 0 10px;

        @media (min-width: 1200px) {
            grid-template-columns: repeat(7, 142px);
            grid-row-gap: 0;
            padding: 0;
        }

        &.is-closed {
            @media (min-width: 1200px) {
                max-height: 270px;
                min-width: 270px;
            }
            .grid-slot:nth-child(n + 6) {
                display: none;

                @media (min-width: 1200px) {
                    display: block;
                }
            }
        }

        .grid-slot {
            background-color: $color-secondary;
            //padding: 0 10px;
            padding-bottom: 25px;

            .card-header {
                background-color: $color-secondary !important;
                color: white;
                padding: 0;
                border: 0 !important;
                border-radius: 0 !important;
                -webkit-appearance: none;

                @media (min-width: 1200px) {
                    background-color: #fff;
                    pointer-events: none;
                }
                h5 {
                    font-size: 16px;
                    text-align: center;
                    color: white;
                    line-height: 55px;
                    min-height: 55px;
                    margin-bottom: 0;
                    position: relative;
                    -webkit-appearance: none;

                    @media (min-width: 1200px) {
                        pointer-events: none;
                    }

                    .carret {
                        position: absolute;
                        top: calc(50% - 6px);
                        right: 10px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 6px 12px 6px;
                        border-color: transparent transparent #003253
                            transparent;
                    }

                    &[aria-expanded="false"] > .carret {
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12px 6px 0 6px;
                        border-color: #000325 transparent transparent
                            transparent;
                    }
                }
            }

            .slot {
                @media (min-width: 1200px) {
                    &.collapse:not(.show) {
                        display: block;
                    }
                }
                .card-body {
                    border: 0;
                    padding: 0;

                    ul {
                        background-color: $color-secondary;
                        list-style: none;
                        margin-bottom: 0;
                        padding: 0 20px;

                        @media (min-width: 375px) {
                            padding: 0 47px;
                        }
                        @media (min-width: 1200px) {
                            padding: 10px;
                        }
                        li {
                            line-height: 40px;
                            margin-bottom: 10px;

                            min-width: 110px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:nth-child(n + 9) {
                                display: none;
                                @media (min-width: 1200px) {
                                    display: block;
                                }
                            }
                            .btn {
                                background-color: white;
                                color: $color-secondary;
                                border-radius: 4px;
                                border: 0;
                                padding: 10px 25px;
                                width: 100%;

                                &:hover {
                                    background-color: $color-primary;
                                    color: white;
                                }

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                        }

                        &.open {
                            li:nth-child(n + 9) {
                                display: block;
                            }
                        }
                    }
                }

                .card-footer {
                    background-color: $light-grey;
                    border: 0;

                    button {
                        &:before {
                            content: "voir plus de créneaux";
                        }

                        &[data-collapse="false"] {
                            &:before {
                                content: "voir moins de créneaux";
                            }
                        }
                    }
                }
            }
        }
    }
}

.flipdown {
    width: 240px !important;
    display: block;
    margin: 10px auto 0;

    @media (max-width: 550px) {
        margin: 10px auto;
    }

    .rotor-group {
        .rotor-group-heading {
            margin-bottom: 7px;
        }

        .rotor-group-heading:before {
            font-size: 12px;
            height: 22px;
            font-weight: 800;
        }

        &:nth-child(1) {
            &:before {
                content: "";
                position: absolute;
                bottom: 20px;
                left: 115px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #ee3780;

                @media (max-width: 550px) {
                    height: 6px;
                    width: 6px;
                    left: 71px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 50px;
                left: 115px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #ee3780;

                @media (max-width: 550px) {
                    height: 6px;
                    width: 6px;
                    left: 71px;
                    bottom: 3px;
                }
            }
        }

        &:nth-child(2) {
            padding-right: 0;

            &:before,
            &:after {
                content: unset !important;
            }
        }

        &:nth-child(3) {
            display: none;
        }

        &:nth-child(4) {
            display: none;
        }
    }

    .rotor {
        &:after {
            border-top: solid 1px #f1f1f1 !important;
        }
    }

    .rotor-leaf {
        .rotor-leaf-rear,
        .rotor-leaf-front {
            color: #ee3780 !important;
            background-color: #fff !important;
        }
    }

    .rotor-top {
        color: #ee3780 !important;
        background-color: #fff !important;
    }

    .rotor-bottom {
        color: #ee3780 !important;
        background-color: #fff !important;
    }
}
