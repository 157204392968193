.overflow-hidden {
  overflow-x: hidden;
}
.no-scroll {
  overflow: hidden !important;
}

// BG
  .bg-primary {
    background-color: $color-primary !important; 
  }
  .bg-light-grey {
    background-color: $light-grey;
  }
  .bg-blue {
    background-color: #b6cad1!important;
  }
  .bg-green {
    background-color: $green!important;
  }
  .bg-dark-green {
    background-color: $dark-green!important;
  }
  .bg-light-green {
    background-color: $light-green!important;
  }

// COLOR
  .color-primary {
    color: $color-primary;
  }
  .color-secondary {
    color: $color-secondary;
  }
  .color-grey {
    color: $grey;
  }
  .color-green {
    color: $green!important;
  }
  .color-dark-green {
    color: $dark-green!important;
  }
  .color-light-green {
    color: $light-green!important;
  }
  .color-black {
    color: #000!important;
  }
  .text-primary {
    color: $gold!important;
  }
// Elements
  .call-to-action {
    display: block; 
    width: 190px;
    padding: 0;
    color: white;
    border-radius: 40.6px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 900;
    background-color: $color-primary;
    height: 60px;
    line-height: 60px;
    
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
    
    &.price-grid {
      display: inline-block;
    }
    
    &.distinction {
      background-color: $green;
    }
    
    &.elegance {
      background-color: $blue;
    }
    
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      margin-top: -8px;
      
      @media(min-width: 768px) {
        height: 30px;
        width: 30px;
        margin-right: 16px;
      }
    }
    
    @media(min-width: 768px) {
      width: 304px;
      border-radius: 65px;
      
      &.price-grid {
        max-width: 200px;
      }
    }
    @media(min-width: 1200px) {
      width: 304px;
      text-align: center;
      &.price-grid {}
    
    }
    
    
  }
  
  .btn-price-grid {
    display: inline-block;   
    width: 190px; 
    padding: 0; 
    color: white;
    border-radius: 40px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 900;
    background-color: $color-primary;
    height: 60px;
    line-height: 60px;
    
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
    
    &.distinction {
      background-color: $green;
    }
    
    &.elegance {
      background-color: $blue;
    }
  
    @media(min-width: 1200px) {
      width: 150px;
      font-size: 14px;
      pointer-events: none;
    }
  }
  
  .fz14 {
    font-size: 14px;
  }
  
//padding
  .nopadding {
    padding: 0 !important;
  }
    
//margin
  .nomargin {
    margin: 0 !important;
  } 

// link
  a.nodeco {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .cursorpointer {
      cursor:pointer !important;
  }
  
// text 
  ul.custom-list {}
  
// border-radius

// animations
  .transition {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .transition-bezier {
    -webkit-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
    -moz-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
    transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
  }
  .shadow {
    -webkit-box-shadow: 0 0 20px 1px rgba(0,0,0,0.5);
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.5);
  }

  .opacity-hover {
    opacity: 1;
      &:hover {
        opacity: 0.7;
      }
  }
  
// images  
  .cover {
    object-fit: cover;
    object-position: center;
  }
  .cover-center {
    object-fit: cover;
    object-position: center;
  }

/* Underline Right */
  .hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    background: transparent;
  }
  .hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-primary;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.8, 0, 0.4, 1);
    transition-timing-function: cubic-bezier(0.8, 0, 0.4, 1);
  }
  .hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
    color: white;
  }
  .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

/* Underline Reveal */
  .hvr-underline-reveal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(100%) translateZ(0);
    transform: perspective(100%) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-reveal:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-primary;
    height: 100%;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before, .hvr-underline-reveal.dropdown-toggle[aria-expanded="true"]:before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

/* Underline From Center */
  .hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: $color-primary;
    height: 1px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before{
    left: 0;
    right: 0;
  }

/* Sweep To Top */
  .hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
    color: white;
  }
  .hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
