footer {
    margin-top: 57px;
    
    @media(min-width: 768px) {
        margin-top: 60px;
    }
    
    &.is-sticky {
        @media(max-width: 767px) {
            margin-bottom: 80px;
        }
        @media(max-width: 1199px) {
            margin-bottom: 120px;
        }
        
    }
    &.in-selection {
        margin-bottom: 140px;
        
        @media(min-width: 768px) {
            margin-bottom: 140px;
        }
        @media(min-width: 1200px) {
            margin-bottom: 140px;
        }
    }
    
    #sub-footer-1 {
        width: 100%;
        padding: 0 20px;
        //margin-bottom: 40px;
        
        @media(min-width: 768px) {
            padding: 0 40px;
        }
        /* 
        @media(min-width: 1200px) {
            padding: 0 calc(6% + 28px);
        }
        */
        
        img, span {
            margin-bottom: 34px;
            max-width: 260px;
            @media(min-width: 768px) {
                //margin-bottom: 50px;
                
               /*
               &#insta {
                    width: 299px;
                    height: 67px;
                }
                */
            }
            @media(min-width: 768px) {
                max-width: 235px;
            }
        }
        
        #tel {
            img {
                height: 55px;
                width: 55px;
            }
            div {
                margin-left: 12px;
                font-size: 16.2px;
                line-height: 1.1;
                @media(min-width: 992px) {
                    font-size: 11.9px;
                    margin-top: 3px;
                    margin-left: 6px;
                }
                @media(min-width: 1200px) {
                    font-size: 16.2px;
                    margin-top: inherit;
                    margin-left: 12px;
                }
            }
        }
        
        .footer-mentions {
            margin-top: 6px;
            color: #777;
            font-size: 12px;
        }
    }
    
    .ml-text {
        font-size: 12px;
        color: $grey;
        padding: 0 20px 10px 20px;
        @media(min-width: 768px) {
            padding: 0 40px 10px 40px;
        }
    }
    
    #sub-footer-2 {
        height: 123px;
        background-color: #d8d8d8;
        padding: 20px 40px;
        
        @media(min-width: 768px) {
            height: 40px;
        }
        
        #links {
            height: 100%;
            @media(min-width: 768px) {
                width: 443px;   
                
                :nth-child(2) {
                    &:before {
                        content: '•';
                        margin-right: 10px;
                        margin-left: 10px;
                        
                        
                    }
                    &:after {
                        content: '•';
                        margin-right: 10px;
                        margin-left: 10px;
                        text-decoration: none;
                    }
                }
            }
            
            a {
                color: #777;
                font-size: 12px;
                
                &:hover {
                    
                    &:before, &:after {
                        text-decoration: none;
                    }
                    
                }
                
            }
        }
        
    }
    
    #rendez-vous-sticky {
        //margin-top: 50px;
        display: flex;
        height: auto;
        width: 100%;
        padding: 10px 0;
        background-color: $green;
        color: #fff;
        box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.25);
        font-size: 17.6px;
        line-height: 1; 
        position: fixed;
        z-index: 3;
        bottom: -170px;
        @media(min-width: 1280px) {
            padding-right: calc(400px)!important;
            width: 100%;
        }
        div:nth-of-type(2){
            width: 100%;
            
            @media(min-width: 1200px) {
                width: auto;
            }
        }
        &.is-sticky {
            bottom: -120px;
            
            @media(max-width: 767px) {
                //height: 80px;
                bottom: 0;
                
                .call-to-action {
                    margin: 0 !important;
                }
                #count-selected {
                    display: none!important;
                }
            }
            @media(max-width: 1199px) {
                //height: 120px;
                bottom: 0;
                
                .call-to-action {
                    margin: 0 auto 0 auto !important;
                }
                
                #count-selected {
                    display: none!important;
                }
                
            }
        }
        
        &.in-selection {
            bottom: 60px;
            
            @media(min-width: 768px) {
                bottom: 140px;
            }
            @media(min-width: 1024px) {
                bottom: 80px;
            }
            @media(min-width: 1280px) {
                height: auto;
                
                bottom: 0;
            }
        }
        &.in-selection {
           // height: 140px;
            
            #count-selected {
                display: block!important;
            }
        }
        
        .footer-logo {
            position: relative;
            img {
                height: 60px;
            }
        }
        @media(min-width: 768px) {
            height: 80px;
            padding: 20px;
        }
        @media(min-width: 1200px) {
            bottom: -120px;
        }
        
        
        #count-selected {
            font-size: 20px;
            
            @media(min-width: 768px) {
                font-size: 28px;
            }
            @media(min-width: 1200px) {
                margin-right: 40px;
            }
        }
        
    }
    
    &.elegance {
        #rendez-vous-sticky {
            background-color: $blue;
        }
    }
}