/* ---------------------------------------------------------------------- */
/* Fonts
/* ---------------------------------------------------------------------- */
@include fontFace('Circe', 100); // thin
@include fontFace('Circe', 200); // Extra Light
@include fontFace('Circe', 300); // Light
@include fontFace('Circe', 400); // Regular
@include fontFace('Circe', 700); // Bold
@include fontFace('Circe', 900); // Black

$font-primary: 'Circe', sans-serif;

.c-100 {
    font-family: 'Circe';
    font-weight: 100;
}
.c-200 {
    font-family: 'Circe';
    font-weight: 200;
}
.c-300 {
    font-family: 'Circe';
    font-weight: 300;
}
.c-400 {
    font-family: 'Circe';
    font-weight: 400;
}
.c-700 {
    font-family: 'Circe';
    font-weight: 700;
}
.c-800 {
    font-family: 'Circe';
    font-weight: 800;
}
.c-900 {
    font-family: 'Circe';
    font-weight: 900;
}
