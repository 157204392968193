#contact-form {
    .form-card {
        padding: 25px;
        //background-color: #fff;
        width: 100%;
        @media(min-width: 768px) and (max-width: 1199px) {
            padding: 25px 159px;
        }
        @media(min-width: 1200px) {
            width: 500px;
            padding: 0;
            //box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        }
        .form-card-body {
            @media(min-width: 1200px) {
                padding: 0 0 72px 0;
            }
            .label-title {
                font-size: 15px;
                line-height: 30px;
                margin-bottom: 5px;
            }
            
            .row {
                .col {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .form-group {
                    
                    margin-bottom: 15px;
                    
                    .form-control {
                        height: 40px;
                        font-size: 14px;
                        background-color: #f5f5f5;
                        border: 0;
                        padding-top: 9px!important;
                        border-radius: 0;
                        
                        &:focus {
                            box-shadow: none;
                        }
                        
    
                    }
                    .tel-group {
                        border-radius: 0;
                        .input-group-prepend {
                            background-color: #adadad;
                            width: 99px;
                        }
                        .v-divider {
                            height: 100%;
                            width: 1px;
                            background-color: #cecece;
                        }
                        select {
                            color: #adadad;
                            
                            border-radius: 0;
                            border-right: 2px solid #dcdcdc;
                            position: relative;
                            -webkit-appearance: none;
                            background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc) !important;
                            background-position: calc(100% - 11px) calc(1em + 5px), calc(100% - 7px) calc(1em + 5px), calc(100% - -0.5em) 0.5em !important;
                            background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1px 1.5em !important;
                            option {
                                color: #adadad;
                            }
                        }
                        
                    }
                    .civility-radio {
                        padding: 0 15px;
                        label {
                            width: 100%;
                            font-size: 15px;
                            line-height: 2;
                            color: #cdcdcd;
                            
                            &[for="civilite-1"]:after, &[for="civilite-2"]:before {
                                content: '';
                                -webkit-transition-duration: .3s;
                                -o-transition-duration: .3s;
                                transition-duration: .3s;
                                position: relative;
                                display: inline-block;
                                left: 0;
                                top: 5px;
                                background-color: #fff;
                                border: 2px solid;
                                border-radius: 50%;
                                width: 20px;
                                height: 20px;
                                -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                            }
                            &[for="civilite-1"]:after {
                                margin-left: 9px;
                            }
                            &[for="civilite-2"]:before {
                                margin-right: 9px;
                            }
                        }
                        input:checked {
                            color: $color-primary;
                            & ~ span{
                                //font-family: 'ITC-Avant-Garde-Gothic-Std-Demi'!important;
                                font-weight: 700;
                            }
                            
                            & ~ label:before, & ~ label:after {
                                border: 7px solid;
                                color: $color-primary;
                            }
                        }
                    } 
                    
                    .optin-checkbox {
                        padding-left: 32px;
                        position: relative;
                        
                        label {
                            font-size: 12px;
                            line-height: 1.42;
                            color: #979797;
                            /*
                            &:before {
                                content: '';
                                width: 18px;
                                height: 18px;
                                -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                                border: 2px solid $color-primary;
                                position: absolute;
                                left: 0;
                                top: 0;
                                -webkit-transition-duration: .3s;
                                -o-transition-duration: .3s;
                                transition-duration: .3s;
                            }
                            */
                        }
                        input:checked {
                            color: $color-primary;
                            border-width: 6px;
                            & ~ label:before {
                                border: 7px solid;
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
            .submit-form {
                margin-top: 16px;
                margin-bottom: 12px;
                width: 187.6px;
                height: 55px;
                border-radius: 27.5px;
                font-size: 14px;
                line-height: 16px;    
                padding-top: 9px;
                background-color: $color-primary;
                &:hover {
                    background-color: lighten($color-primary, 10%) ;
                }
            }
            .mandatory {
                font-size: 12px;
                color: #a1a1a1;
                margin-bottom: 30px;
            }
            .cgu {
                font-size: 12px;
                line-height: 15px;
                color: #979797;
            }
            a {
                color: #979797;
            }
            
        }
    }
    
    &.elegance {
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $blue;
            border-color: $blue;
        }
        .submit-form {
            background-color: $light-blue !important;
            &:hover {
                background-color: $blue!important;
            }
        }
    }
}

.control-group {
    .form-row {
        line-height: 30px;
        padding: 0 !important;
        margin-bottom: 26px;
        margin-top: 20px;
        margin-right: 0 !important;
        margin-left: 0 !important;
        @media(min-width: 992px) {
            height: 30px;
            .civilite {
                    width: 115px;
                    margin-right: 30px !important;
                }
                .md {
                    width: 115px;
                    margin-right: 10px !important;
                    .control {
                        margin-left: 10px;
                    }
                }
                .mr {
                    width: 115px;
                    .control {
                        margin-right: 10px;
                    }
                }
            }
        @media(max-width: 991.98px) {
            .civilite {
                margin-bottom: 5px !important;
            }
            .md {
                text-align: right !important;
                .control {
                    margin-right: 20px !important;
                    margin-left: 10px;
                }
            }
            .mr {
                text-align: left !important;
                .control {
                    margin-left: 20px !important;
                    margin-right: 10px;
                }
            }
        }
    } 
}
.control {
    //display: block;
    position: relative;
    padding: 0 10px;
    margin-bottom: 17px;
    padding-top: 0px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    //top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #FFF;
    border: 1px solid #cdcdcd;
    &.border-color {
        border: 1px solid $color-primary;    
    }
    border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #f5f5f5;
}

.control input:checked ~ .control_indicator {
    background: $color-primary;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #df2b73;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}

.control-radio .control_indicator {
    border-radius: 50%;
}
.control-radio .control_indicator:after {
    left: 6px;
    top: 6px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #ffffff;
    transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
    background: #7b7b7b;
}
.control-radio .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: $color-primary;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

.control-checkbox .control_indicator:after {
    left: 7px;
    top: 3px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #2aa1c0;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

.custom-optin-checkbox {
    margin-top: 6px !important;
    margin-bottom: -20px !important;
} 
.custom-optin-checkbox-text {
    position: relative;
    font-size: 12px;
    line-height: 1.42;
    color: #979797;
}
.custom-control-label {
    color: #979797;
    font-size: 14px;
    line-height: 25px;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #a07515;
    background-color: #a07515;
}

.custom-control-input.has-error ~ .custom-control-label {
    color: #dc3545;
    
    &:before {
        border-color: #dc3545;
    }
}