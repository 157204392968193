main {
    width: 100%;
    @media (min-width: 1200px) {
        max-width: calc(100% - 400px);
    }
    
    #sticky-bar {
        position: sticky;
        bottom: 0;
        height: 160px;
        width: 100%;
        background-color: $dark-green;
        
        @media(min-width: 1024px) {
            height: 80px;
        }
    }
}

aside {
    width: 100%;
    background-color: $green; 
    position: relative;
    z-index: 4;
     
    @media (min-width: 1200px) {
        width: max(29.3%, 400px);
        max-width: 400px;
    }
    
    #aside-container {
        @media(min-width: 1200px) {
            position: sticky;
            top: 0;
            height: 100vh;
            overflow-y: scroll;
            
        }
    }
    
    #aside-header {
        background-color: $dark-green;
        padding: 30px 20px;
        bottom: -0;
        width: 100%;
        //z-index: 3;
        @media(min-width: 768px) {
            padding: 0;
            //position: fixed;
            bottom: 0;
        }
        @media(min-width: 1200px) {
            position: static;
            padding: 30px 40px;
        }
        .aside-header-top {
            @media(min-width: 768px) {
                min-height: 80px;
            }
            .header-title {
                font-size: 26px;
                line-height: 1.15;
                margin-bottom: 30px;
                @media(min-width: 768px) {
                    font-size: 20px;
                    margin-left: 20px;
                    margin-bottom: 0;
                }
                @media(min-width: 992px) {
                    margin-right: 30px;
                }
                @media(min-width: 1200px) {
                    font-size: 26px;
                    line-height: 1.15;
                    margin-right: inherit;
                    margin-left: inherit;
                    margin-bottom: 30px;
                }
            }
            
            .docs {
                width: 262px;
                @media(min-width: 768px) {
                    width: auto;
                }
                @media(min-width: 1200px) {
                    width: 262px;
                }
                .doc {
                    width: 74px;
                    @media(min-width: 768px) {
                        width: auto;
                        margin-right: 55px;
                        /*
                        &:last-child {
                            margin-right: 0;
                        }
                        */
                    }
                    @media(min-width: 992px) {
                        margin-right: 20px;
                    }
                    @media(min-width: 1200px) {
                        margin-right: inherit;
                        width: 74px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .picto-container {
                        height: 60px;
                        width: 60px;
                        background-color: #fff;
                        border-radius: 50%;
                        margin-bottom: 13px;
                        @media(min-width: 768px) {
                            height: 50px;
                            width: 50px;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        @media(min-width: 1200px) {
                            height: 60px;
                            width: 60px;
                            //margin-right: inherit;
                            margin-bottom: 13px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        img {
                            height: 27px;
                            width: 27px;
                            @media(min-width: 1200px) {
                                height: 32px;
                                width: 32px;
                            }
                        }
                    }
                    span {
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }
        }
        .actions {
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 3;
            display: flex;
            
            @media(min-width: 768px) {
                position: static;
            }
            .rdv, .inscription {
                width: 50%;
                height: 60px;
                line-height: 60px;
                @media(min-width: 992px) {
                    width: 175px;
                    height: 80px;
                    line-height: 80px;
                }
            }
            .rdv {
                background-color: $gold; 
            }
            .inscription {
                background-color: $green; 
            }
            #count-selected {
                padding: 10px 0 ;
                background-color: $dark-green;
                color: #fff;
            }
        }
    }
    
    
    #aside-body {
        padding: 40px;
        
        
        .body-title {
            font-size: 26px;
            line-height: 1.15;
        }
        .body-subtitle {
            margin-top: 21px;
            margin-bottom: 34px;
            line-height: 1;
        }
        .form-group {
            margin-bottom: 10px!important;
            
            .form-control {
                height: 50px!important;
                border-radius: 2px!important;
                font-size: 16px!important;
            }
        }
        .custom-control-label {
            color: white!important;
        }
        .custom-optin-checkbox-text {
            margin-top: 13px;
            margin-bottom: 28px;
        }
        .submit-form {
            margin-top: 28px!important;
            margin-bottom: 20px!important;
        }
    }
    
    &.elegance {
        background-color: $gold; 
        #aside-header {
            background-color: $dark-gold;
            .inscription {
                background-color: $blue; 
            }
        }
       
    }
}
 
#presentation {
    position: relative;
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 63px;
            margin-bottom: 42px;
        }
    }
    
    #presentationCarousel {
        margin-top: 7px;
        .carousel-inner {
            position: relative;
            
            .triangle {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    height : 0;
                    width : 0;
                    border-top : 135px solid $gold;
                    border-right : 135px solid transparent;
                }
                .infos { 
                    transform: rotate(-45deg);
                    position: absolute;
                    z-index: 3;
                    top: 40px;
                    left: -15px;
                    font-size: 18px;
                    line-height: 15px;
                    color: white;
                    font-weight: 700;
                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            
        }
        .carousel-item {
            .img-container {
                height: 168px;  
                
                @media(min-width: 768px) {
                    height: 463px;
                    width: calc(100% - 354px - 60px);
                }
                @media(min-width: 1200px) {
                    //margin-top: 40px;
                    width: calc(100% - 423px - 80px);
                }
            } 
            .carousel-caption {
                position: static;
                background-color: #fff;
                padding: 20px;
                margin-top: 62px;
                color: $color-primary;
                font-size: 24px;
                line-height: 0.92;
                
                @media(min-width: 768px) {
                    font-size: 32px;
                    padding: 30px;
                    line-height: 0.88;
                    margin-top: 0;
                    width: 414px;
                    
                }
                @media(min-width: 1200px) {
                    padding: 40px;
                    width: 503px;
                }
                
                .caption-details {
                    font-size: 16px;
                    line-height: 1.25;
                    margin-top: 6px;
                    
                    @media(min-width: 768px) {
                        margin-top: 20px;
                        font-size: 22px;
                        line-height: 1.09;
                    }
                }
                .caption-picto {
                    display: none;
                    @media(min-width: 768px) {
                        display: block;
                        height: 100px;
                        width: 100px;
                        position: absolute;
                        bottom: 0;
                        right: 30px;
                        @media(min-width: 992px) {
                            right: 40px;
                        }
                    }
                }
            }
        }
        
        .carousel-control-prev, .carousel-control-next {
            top: 168px;
            height: 40px;
            width: 40px;
            background-color: $green;
            opacity: 1;
            
            &.carousel-control-next {
                left: 40px;
            }
            
            @media(min-width: 768px) {
                height: 50px;
                width: 50px;
                top: 413px;
                left: calc(100% - 354px - 60px);
                
                &.carousel-control-next {
                    left: calc(100% - 354px - 60px + 50px);
                }
            }
            
            @media(min-width: 1200px) {
                left: calc(100% - 423px - 80px);
                
                &.carousel-control-next {
                   left: calc(100% - 423px - 80px + 50px);  
                }
            }
            
            
        }
        
        .custom-indicators {
            position: absolute;
            z-index: 2;
            top: 168px;
            right: 15px;
            margin-top: 5px;
            
            @media(min-width: 768px) {
                margin-top: 0;
                top: calc(463px - 38px);
                right: initial; 
                left: calc(100% - 294px);
            }
            @media(min-width: 1200px) {
                right: inherit;
                left: calc(100% - 383px); 
                
            }
            .current-slide {
                font-size: 25.6px;
                line-height: 30px;
                color: $gold;
                
                @media(min-width: 768px) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
        }
    }
    
    &.elegance {
        .title {
            color: $light-blue;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 69px;
            background-color: $color-secondary;
            background-image: url('/img/elegance/pattern-prestations.png');
            background-repeat: repeat-y;
            background-size: 20px;
            @media(min-width: 768px) {
                height: 180px;
                width: 80px;
                background-size: 40px;
            }
        }
        .carousel-control-prev, .carousel-control-next {
            background-color: $color-primary !important;
        }
        .custom-indicators {
            .current-slide {
                color: $color-primary !important;
            }
        }
    }
}
 
#stock {
    background-color: $light-grey; 
    padding-bottom: 16px;
    @media(min-width: 768px) {
        padding-bottom: 41px;
    }
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 74px;
            margin-bottom: 15px;
        }
    }
    .subtitle {
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 10px;
        color: $gold;
        @media(min-width: 768px) {
            font-size: 38px;
            margin-bottom: 10px;
        }
        
    }
    .subtitletext {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 18px;
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }
    .subtext {
        font-size: 12px;
        color: $grey;
        margin-top: 10px;
        width: 70%;
    }
    
    .stock-container {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media(min-width: 768px) {
            width: calc(100% - 60px);
        }
        @media(min-width: 1200px) {
            width: calc(100% - 80px);
        }
        
        // CARD
        .accordion {
            
            margin-bottom: 10px;
            
            .card {
                border: 0 solid rgba(0, 0, 0, 0);
                border-radius: 0;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                .card-header {
                    position: relative;
                    border-bottom: 0 solid rgba(0, 0, 0, 0);
                    background-color: transparent;
                    padding: 0 0;
                    border-radius: 0;
                    
                    .btn-link {
                        font-weight: 400;
                        color: white;
                        text-decoration: none;
                        &:hover {
                            color: white;
                            text-decoration: inherit;
                        }
                    }
                    
                    button.trigger {
                        background-color: $gold;
                        position: relative;
                        
                        padding: 20px 20px;
                        border-radius: 0;
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: 1;
                            right: 20px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 20px;
                            width: 40px;
                            background-image: url(/img/less.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            -webkit-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                            -moz-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                            transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                        }
                        &.collapsed {
                            &:after {
                                -webkit-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                                -moz-transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                                transition: all 0.25s cubic-bezier(0.8, 0, 0.2, 1);
                                background-image: url(/img/more.svg);
                            }
                        }
                    
                        .main-items-stock {
                            position: relative;
                            
                            .appartment {
                                font-size: 16px;
                                display: block;
                                @media(min-width: 768px) {
                                    display: inline-block;
                                    width: 15%;
                                    margin-right: 8%;
                                }
                                @media(min-width: 992px) {
                                    font-size: 18px;
                                    width: 28%;
                                    margin-right: 7%;
                                }
                                @media(min-width: 1200px) {
                                    width: 27%;
                                    margin-right: 4%;
                                }
                            }
                            
                            .size {
                                font-size: 16px;
                                display: block;
                                @media(min-width: 768px) {
                                    display: inline-block;
                                    width: 22%;
                                    margin-right: 8%;
                                }
                                @media(min-width: 992px) {
                                    font-size: 18px;
                                    margin-right: 7%;
                                    width: 18%;
                                }
                                @media(min-width: 1200px) {
                                    width: 22%;
                                    margin-right: 4%;
                                }
                                
                            }
                            
                            .available {
                                font-size: 16px;
                                display: block;
                                @media(min-width: 768px) {
                                    display: inline-block;
                                    width: 110px;
                                }
                                @media(min-width: 992px) {
                                    font-size: 18px;
                                    width: 26%;
                                }
                                
                            }
                            
                            .rdv {
                                font-size: 16px;
                                margin: 20px 0 0 0;
                                @media(min-width: 768px) {
                                    position: absolute;
                                    right: 50px;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                                @media(min-width: 992px) {
                                    top: -10px;
                                }    
                                @media(min-width: 1200px) {
                                    line-height: 20px;
                                    top: 0;
                                }
                                
                                
                            }
                            
                        }
                    }
                }
                
                .card-body {
                    padding: 0;
                    .card-body-top {
                        height: 70px;
                        background-color: #efefef;
                        //line-height: 50px;
                        padding: 10px 40px;
                        font-size: 16px;
                    }
                    .card-body-item {
                        font-size: 16px;
                        border-bottom: 1px solid $light-grey;
                        @media(max-width: 575.98px) {
                            padding: 20px 20px;
                        }
                        @media(max-width: 1199.98px) {
                            padding: 30px 20px;
                            line-height: 24px;
                            position: relative;
                            .lot {
                                display: inline;
                            }
                            .surface {
                                display: inline;
                            }
                            .tva20 {
                                display: inline;
                                margin-right: 10px;
                            }
                            .plan {}
                        }
                        .exterieurs {
                            // line-height: 20px;
                            // position: relative;
                            // top: 5px;
                        }
                        .rdv {
                            @media(max-width: 575.98px) {
                                margin-top: 10px;
                            }
                            @media(min-width: 576px) and (max-width: 1199.98px) {
                                position: absolute;
                                top: 15px;
                                right: 20px;
                            }
                            
                        }
                        line-height: 54px;
                        padding: 20px 40px;
                    }
                }
                
                .card-header, .card-body {
                    @media(min-width: 1200px) {
                        .w5 {width: 5%;}
                        .w10 {width: 12%;}
                        .w20 {width: 20%;}
                        .w25 {width: 25%;}
                    }
                }
                .new {
                    color: $gold;
                    text-align: center;
                    text-transform: uppercase;
                    margin-left: 30px;
                }
                .btn-cta {
                    height: 50px;
                    line-height: 35px;
                    border-radius: 4px;
                    border: solid 2px $color-primary;
                    text-align: center;
                    display: inline-block;
                    min-width: 130px;
                    @media(min-width: 1200px) {
                        padding: 0;
                    }
                    @media(min-width: 1200px) and (max-width: 1365.98px) {
                        min-width: 90px;
                    }
                    @media(min-width: 1366px) {
                        min-width: 135px;
                    }
                    
                    width: 102px;
                    &:first-child {
                        margin: 0 5px 0 0;
                    }
                    &:hover {
                        background: $color-primary;
                        color: white;
                    }
                    &.selected {
                        background: $color-primary;
                        color: white; 
                       
                        &:after {
                          content: ' ';
                          display: inline-block;
                          height: 13px;
                          width: 18px;
                          background-image: url('/img/path.svg');
                          position: relative;
                          margin-left: 8px;
                        }
                        @media(min-width: 1200px) and (max-width: 1365.98px) {
                            min-width: 115px;
                        }
                    }
                    &:disabled {
                        border: solid 1px #efefef;
                        background-color: #efefef;
                        color: #fff;
                        opacity: 1;
                        cursor: not-allowed;
                    }
                    @media(max-width: 575.98px) {
                        display: inline-block;
                        min-width: 125px;
                    }
                    /*
                    @media(min-width: 576px) and (max-width: 1199.98px) {
                        &:first-child {
                            margin: 0 0 5px 0;
                        }
                    }
                    */
                    
                }
                // .btn-cta {
                //     height: 50px;
                //     line-height: 35px;
                //     border-radius: 4px;
                //     border: solid 2px white;
                //     text-align: center;
                //     display: inline;
                //     min-width: 130px;
                //     color: white;
                //     position: static;
                //     bottom: -60px;
                //     left: 0;
                //     @media(min-width: 425px) {
                //         bottom: inherit;
                //         left: inherit;
                //         top: 0;
                //         right: 0;
                //     }
                //     @media(min-width: 768px) {
                //         top: 0;
                //         right: 120px;
                //     }
                //     @media(min-width: 1200px) {
                //         top: -10px;
                //     }
                //     &:hover {
                //         background: $color-primary;
                //         border-color: $color-primary;
                //     }
                // }
            }
            
            &.nocollapse {
                .card {
                    .card-header {
                        button.trigger {
                            cursor: auto;
                            background-color: $gold;
                            padding: 20px 20px 90px 20px;
                            @media(min-width: 425px) {
                                padding: 20px 20px;
                            }
                            border-radius: 0;
                            &:after {
                                display: none;
                            }
                            
                            .main-items-stock {
                                position: relative;
                                
                                .appartment {
                                    @media(min-width: 1200px) {
                                        display: inline;
                                        margin-right: 0;
                                    }
                                }
                                
                                .btn-cta {
                                    height: 50px;
                                    line-height: 35px;
                                    border-radius: 4px;
                                    border: solid 2px white;
                                    text-align: center;
                                    display: inline;
                                    min-width: 130px;
                                    color: white;
                                    position: absolute;
                                    bottom: -60px;
                                    left: 0;
                                    @media(min-width: 425px) {
                                        bottom: inherit;
                                        left: inherit;
                                        top: 0;
                                        right: 0;
                                    }
                                    @media(min-width: 768px) {
                                        top: 0;
                                        right: 120px;
                                    }
                                    @media(min-width: 1200px) {
                                        top: -10px;
                                    }
                                    @media(min-width: 1200px) {
                                        
                                    }
                                    &:hover {
                                        background: $color-primary;
                                        border-color: $color-primary;
                                    }
                                }
                                
                                .rdv {
                                    font-size: 16px;
                                    position: absolute;
                                    line-height: 20px;
                                    bottom: -45px;
                                    right: 0;
                                    @media(min-width: 425px) {
                                        bottom: 0;
                                        right: 10px;
                                    }
                                    @media(min-width: 768px) {
                                        top: 0;
                                        right: 20px;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        
    }
    
    &.elegance {
        .title {
            color: $light-blue;
        }
        .accordion {
            .card {
                button.trigger {
                    background-color: $light-blue !important;
                    @media (min-width: 1200px) {
                        .rdv {
                            color: $gold;
                        }
                    }
                }
            }
        }
    }
}

#prestations {
    position: relative;
    
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 63px;
            margin-bottom: 42px;
        }
    }
    
    #prestationsCarousel {
        margin-top: 7px;
        
        .carousel-item {
            position: relative;
            .img-container {
                height: 168px;  
                
                @media(min-width: 768px) {
                    height: 463px;
                    width: calc(100% - 354px - 60px);
                }
                @media(min-width: 1200px) {
                    width: calc(100% - 423px - 80px);
                }
            } 
            .carousel-caption {
                position: static;
                background-color: #fff;
                padding: 20px;
                margin-top: 62px;
                color: $color-primary;
                font-size: 24px;
                line-height: 0.92;
                
                @media(min-width: 768px) {
                    font-size: 32px;
                    padding: 30px;
                    line-height: 0.88;
                    margin-top: 0;
                    width: 414px;
                }
                @media(min-width: 1200px) {
                    padding: 40px;
                    width: 503px;
                }
                
                .caption-details {
                    font-size: 16px;
                    line-height: 1.25;
                    margin-top: 6px;
                    
                    @media(min-width: 768px) {
                        margin-top: 20px;
                        font-size: 18px;
                        line-height: 1.09;
                    }
                }
            }
        }
        
        .carousel-control-prev, .carousel-control-next {
            top: 168px;
            height: 40px;
            width: 40px;
            background-color: $green;
            opacity: 1;
            
            &.carousel-control-next {
                left: 40px;
            }
            
            @media(min-width: 768px) {
                height: 50px;
                width: 50px;
                top: 413px;
                //left: calc(100% - 354px - 60px);
                left: 314px;
                &.carousel-control-next {
                    left: 364px;
                    //left: calc(100% - 354px - 60px + 50px);
                }
            }
            
            @media(min-width: 1200px) {
                //left: calc(100% - 423px - 80px);
                left: 403px;
                &.carousel-control-next {
                    left: 453px;
                   //left: calc(100% - 423px - 80px + 50px);  
                }
            }
            
            
        }
        
        .custom-indicators {
            position: absolute;
            z-index: 2;
            top: 168px;
            right: 15px;
            margin-top: 5px; 
            
            @media(min-width: 768px) {
                margin-top: 0;
                top: calc(463px - 38px);
                left: initial; 
                right: calc(100% - 294px);
            }
            @media(min-width: 1200px) {
                left: inherit;
                right: calc(100% - 383px); 
                
            }
            .current-slide {
                font-size: 25.6px;
                line-height: 30px;
                color: $color-primary;
                
                @media(min-width: 768px) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
        }
        
        ul {
            margin: 0;
            padding: 0 0 0 20px;
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &.little-text {
                @media(min-width: 768px) {
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
        }
    }
    
    &.elegance {
        .title {
            color: $light-blue;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 69px;
            background-color: $color-secondary;
            background-image: url('/img/elegance/pattern-bv.png');
            background-position: right;
            background-repeat: repeat-y;
            background-size: 20px;
            @media(min-width: 768px) {
                height: 180px;
                width: 80px;
                background-size: 40px;
            }
        }
        .carousel-control-prev, .carousel-control-next {
            background-color: $color-primary !important;
        }
        .custom-indicators {
            .current-slide {
                color: $color-primary;
            }
        }
    }
}

#maquette {
    background-color: $light-grey;
    
    @media(min-width: 768px) {
        padding-bottom: 80px;
    }
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 74px;
            margin-bottom: 15px;
        }
    }
    .subtitle {
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 10px;
        color: $gold;
        @media(min-width: 768px) {
            font-size: 38px;
            margin-bottom: 10px;
        }
        
    }
    .subtitletext {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 18px;
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }
    
    .iframe {
        width: 100%;
        height: 320px;
        @media(min-width: 768px) {
            width: calc(100% - 60px);
            margin: 0 auto;
            height: 498px;
        }
         
    }

    .loader-btn { 
        position: absolute;
        z-index: 2; 
        top: 0;
        bottom: 0; 
        right: 0;
        left: 0;
        margin: auto;
        color: white;
        border-radius: 40.6px;
        background-color: $green;
        text-transform: uppercase;
        font-weight: 900;
        display: block;
        width: 310px;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        width: 250px;
        @media(min-width: 768px) {
            font-size: 16px;
            height: 60px;
            line-height: 60px;
            width: 310px;
        }
    }
    
    &.elegance {
        .title {
            color: $light-blue;
        }
        .loader-btn {
            background-color: $light-blue;
        }
    }
}

#infos {
    position: relative;
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 63px;
            margin-bottom: 42px;
        }
    }
    .content {
        position: relative;
        .pict {
            width: 100%;
            //height: 255px;
            @media(min-width: 768px) {
                width: 46.1%;
                //height: 406px;
            }
            @media(min-width: 992px) {
                width: 50%;
                //height: 406px;
            }
        }
         
        .text {
            position: relative;
            padding: 20px;
            margin: 20px auto 0 auto;
            @media(min-width: 768px) {
                margin: 0 auto 0 auto;
                width: calc(100% - 46.1%);
                padding: 30px;
            }
            @media(min-width: 992px) {
                width: calc(50%);
            }
            
            /*
            @media(min-width: 1200px) {
                width: calc(100% - 33.9%);
            }
            */
            
            .title-bv {
                color: $gold;
                
                font-size: 24px;
                line-height: 22px;
                @media(min-width: 768px) {
                    font-size: 38px;
                    line-height: 34px;
                }
            }
            
            .address-bv {
                font-size: 20px;
                line-height: 22px;
                margin: 15px 0;
                @media(min-width: 768px) {
                    margin: 30px 0;
                    font-size: 26px;
                    line-height: 28px;
                }
            }
            
            p {
                margin: 10px 0 0 0;
                width: 100%;
                @media(min-width: 768px) {
                    width: inherit;
                    margin: 0 0 0 10px;
                }
            }
            
        }
        @media(min-width: 992px) {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
                height : 0;
                width : 0;
                border-bottom : 140px solid $light-green;
                border-left : 140px solid transparent;
                opacity: 0.25;
            }
        }
    }
    &.elegance {
        .title {
            color: $light-blue;
        }
    
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 69px;
            background-color: $color-secondary;
            background-image: url('/img/elegance/pattern-prestations.png');
            background-repeat: repeat-y;
            background-size: 20px;
            @media(min-width: 768px) {
                height: 180px;
                width: 80px;
                background-size: 40px;
            }
        }
        .content {
            &:after {
                border-bottom : 140px solid $gold;
            }
        }
    }
}

#video {
    background-color: $light-grey;
    
    height: inherit;
    
    .title {
        font-size: 28px;
        letter-spacing: -0.93;
        padding-top: 20px;
        color: $light-green;
        margin-bottom: 7px;
        @media(min-width: 768px) {
            font-size: 50px; 
            letter-spacing: -1.67px;
            padding-top: 74px;
            margin-bottom: 15px;
        }
    }  
    
    .subtitle {
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 10px;
        color: $gold;
        @media(min-width: 768px) {
            font-size: 38px;
            margin-bottom: 10px;
        }
        
    }
    
    .subtitletext {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 18px;
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }
    
    .movies {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding-bottom: 20px;
        @media(min-width: 768px) {
            padding-bottom: 60px;
            width: calc(100% - 60px);
        }
        @media(min-width: 992px) {
            padding-bottom: 80px;
            width: calc(100% - 80px);
        }
        .movie {
            width: 100%;
            margin-bottom: 20px;
            @media(min-width: 768px) {
                width: calc(50% - 20px);
            }
            
            .text-movie {
                position: relative;
                .triangle { 
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        z-index: 2;
                        height : 0;
                        width : 0;
                        border-bottom : 60px solid $gold;
                        border-left : 60px solid transparent;
                    }
                    &.second {
                        &:after {
                            border-bottom : 60px solid $green;
                        }
                    }
                }
                .player img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: 3;
                    height: 104px;
                    width: 104px;
                    @media(min-width: 768px) {
                        height: 126px;
                        width: 126px;
                    }
                    @media(min-width: 992px) {
                        height: 168px;
                        width: 168px;
                    }
                }
                .text {
                    position: absolute;
                    z-index: 2;
                    left: 14px;
                    bottom: 9px;
                    font-size: 17.3px;
                    line-height: 1.07;
                    @media(min-width: 768px) {
                        left: 17px;
                        bottom: 11px;
                        font-size: 21.1px;
                        line-height: 1.07;
                    }
                    @media(min-width: 992px) {
                        left: 22px;
                        bottom: 14px;
                    }
                }
                .pict {
                    width: 100%;
                    height: 229px;
                    @media(min-width: 768px) {
                        height: 279px;
                    }
                    @media(min-width: 992px) {
                        height: 371px;
                    }
                }
            }
            
            .embed-responsive {
                max-width: 850px;
            }
        }
    }
    
    &.elegance {
        .title {
            color: $light-blue;
        }
        .movies {
            .text-movie {
                .triangle { 
                    &.second {
                        &:after {
                            border-bottom : 60px solid $blue;
                        }
                    }
                }
            }
            
            .embed-responsive {
                max-width: 850px;
            }
        }
    }
    
}