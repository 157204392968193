@mixin fontFace($font-name, $font-weight: normal/*, $font-alter: 'sans-serif'*/){
  @font-face {
    font-family: "#{$font-name}";
    //font-family: "#{$font-name}, #{$font-alter}";
    font-style: normal;
    font-weight: $font-weight;

    src: local("#{$font-name}"),
      url('../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff2') format('woff2'),
      url('../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff') format('woff'),
      url('../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.ttf') format('ttf'),
      url('../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot') format('eot');
    }
} 

@mixin placeholder($color) {
  ::-webkit-input-placeholder {color:$color;opacity: 1!important;}
  ::-moz-placeholder          {color:$color;opacity: 1!important;}/* Firefox 19+ */
  :-moz-placeholder           {color:$color;opacity: 1!important;}/* Firefox 18- */
  :-ms-input-placeholder      {color:$color;opacity: 1!important;}
}

%clearfix { 
  &:after {
    content: '';
    display: table;
    width: 100%;
    height: 0;
    clear: both;
    opacity: 0;
  }
}